/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 *
 * This portlet uses fullcalendar.js and communicates with it and the server through the use of backbone.js.
 * This calendar supports creation, update and removal of events either by the use of click operations or drag and drop.
 * It also support the possibility to drag other typ of events and drop them in the calendar, for example tasks.
 *
 * To support the possibility to use filter on if a user is participating in a event and on its type a minor update has
 * been required in the third party library fullcalendar.js (added the dropdown filter menu named svFilter).
 *
 * @depends portletutil.js, objectutil.js, keyutil.js, errorutil.js, i18n.js, events.js, backbone.js, fullcalendar.js
 */

import sv from '@sv/core';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import moment from '../../vendor/moment';
import { getPortletResourceUri, getTemplate } from '../../util/portletUtil';
import {
  ActivityDetailView,
  ActivityEditView,
  ActivityModel,
  calculatePopoverPlacement,
  showErrors,
  stripYear,
} from '../util/activityUtil';
import {
  Events as events,
  ObjectUtil as objectUtil,
  KeyUtil as keyUtil,
  DialogUtil as dialogUtil,
  i18n as _i18n,
  ClientUtil as clientUtil,
} from '@sv/util';

let filter = {
    type: 'all',
    mine: false,
  },
  i18nCommon = function (key, args) {
    return _i18n.getText('common', key, args);
  },
  i18n = function (key, args) {
    return _i18n.getText('portlet.social.calendar.calendar', key, args);
  };

// HELPERS -----------------------------------------------------------------
var removeActivityInternal = function (onClose) {
  var that = this;
  $('.modal').modal('hide');
  if (this.model.get('isTask')) {
    dialogUtil.showDialog({
      title: i18n('confirmRemoveTaskTitle'),
      body: i18n('confirmRemoveTaskMessage'),
      buttons: [
        {
          text: i18nCommon('cancel'),
          callback: function () {
            $.proxy(onClose, that)();
          },
        },
        {
          text: i18n('confirmRemoveTaskRemove'),
          callback: function () {
            that.model.destroy({
              success: function (model) {
                // set data to be able to DnD tasks to the calendar (see calendar-portlet.js)
                events.trigger(events.types.activityDeleted, model.attributes);
              },
            });
            $.proxy(onClose, that)();
          },
        },
        {
          text: i18n('confirmRemoveTaskSaveInTasks'),
          callback: function () {
            that.model.allowEmptyStart = true;
            that.model.set({
              start: '',
              end: '',
              allDay: false,
            });
            that.model.save(null, {
              success: function (model) {
                events.trigger(events.types.activityUpdated, model.attributes);
              },
            });
            $.proxy(onClose, that)();
          },
        },
      ],
      errorTitle: i18nCommon('networkErrorTitle'),
      errorMsg: i18nCommon('networkErrorText'),
    });
  } else {
    dialogUtil.showConfirmDialog(
      i18n('confirmRemoveTitle'),
      i18n('confirmRemoveMessage'),
      function (result) {
        if (result) {
          that.model.destroy({
            success: function (model) {
              // set data to be able to DnD tasks to the calendar (see calendar-portlet.js)
              events.trigger(events.types.activityDeleted, model.attributes);
            },
          });
          $.proxy(onClose, that)();
        }
      }
    );
  }
  return false;
};

// MODEL -------------------------------------------------------------------
var Event = ActivityModel.extend({
  allowEmptyStart: false,

  url: function () {
    return (
      getPortletResourceUri(this.portletId, 'events') +
      (this.has('id') ? '&id=' + this.get('id') : '')
    );
  },

  toFullCalendar: function () {
    var event = this.toJSON(),
      start = event.start,
      end = event.end;

    event.start = start ? start / 1000 : start;
    event.end = end ? end / 1000 : end;

    return event;
  },
});

// COLLECTION --------------------------------------------------------------
var Events = Backbone.Collection.extend({
  model: Event,

  url: function () {
    return getPortletResourceUri(this.portletId, 'events');
  },

  initialize: function (models, options) {
    this.portletId = options.portletId;
  },

  toFullCalendar: function () {
    return this.map(function (event) {
      return event.toFullCalendar();
    });
  },
});

// DIALOG VIEW -------------------------------------------------------------
var EditEventView = ActivityEditView.extend({
  templateName: 'edit',

  memberTemplateName: 'member',

  participantTemplateName: 'participant',

  membersActionName: 'members',

  membersWithOwnerActionName: 'membersWithOwner',

  i18n: i18n,

  removeActivity: function () {
    $.proxy(removeActivityInternal, this, function () {
      $('.modal').modal('hide');
    })();
  },
});

// POPOVER VIEWS -----------------------------------------------------------
var CreateEventView = Backbone.View.extend({
  template: function () {
    return getTemplate(this.options.$portlet, 'create');
  },

  events: {
    'keyup [data-fn-event-title]': 'keyUp',
    'submit form': 'cancelOnSubmit',
    'click [data-fn-create]': 'createOnClick',
    'click [data-fn-edit]': 'edit',
  },

  initialize: function (options) {
    this.options = options;

    var template = this.template();
    this.$el.append(template(this.model.toJSON()));

    this.listenTo(this.model, 'invalid', this.handleErrors);

    this.ui = {
      title: this.$el.find('[data-fn-event-title]'),
    };
  },

  render: function () {
    events.trigger(events.types.popoverCreated, this);

    this.options.$target
      .popover({
        html: true,
        title:
          i18n('createEvent') +
          '<button type="button" class="close">&times;</button>',
        placement: calculatePopoverPlacement(this.options.$target),
        content: this.$el,
        animation: false,
        trigger: 'manual',
        container: this.options.popoverContainer,
      })
      .popover('show');

    this.$el
      .closest('.popover')
      .on('click', '.close', $.proxy(this.closePopover, this))
      .on('clickoutside', $.proxy(this.closePopover, this));

    $(document).on('keydown', $.proxy(this.closePopoverOnEscape, this));

    $('[data-fn-request-focus]').trigger('focus');

    events.on(
      events.types.popoverCreated,
      $.proxy(this.closePopoverWhenOtherIsCreated, this)
    );

    return this;
  },

  handleErrors: function (model, errors) {
    showErrors(model, errors, this.$el, i18n);
  },

  keyUp: function (e) {
    if (keyUtil.getKeyCodeFromEvent(e) === keyUtil.KEY.RETURN) {
      this.createOnClick();
    }

    if (keyUtil.getKeyCodeFromEvent(e) === keyUtil.KEY.ESC) {
      this.closePopover();
    }

    this.handleRegularChange(e);
    return;
  },

  cancelOnSubmit: function () {
    return false;
  },

  setModelData: function () {
    var titleValue = this.ui.title.val(),
      title = titleValue ? titleValue.trim() : '';

    this.model.set({
      title: title,
      owner: this.options.user,
      isTask: false,
    });
  },

  createOnClick: function () {
    this.setModelData();
    this.options.collection.create(this.model, {
      wait: true,
      success: $.proxy(function (model) {
        this.closePopover();
        events.trigger(events.types.activityCreated, model.attributes);
      }, this),
    });
    return false;
  },

  edit: function () {
    this.closePopover();
    this.setModelData();
    var editView = new EditEventView({
      portletId: this.options.portletId,
      $portlet: this.options.$portlet,
      model: new Event(this.model.attributes, {
        portletId: this.options.portletId,
      }),
    });
    editView.backingModel = this.model;
    editView.render();

    if (clientUtil.isTouchDevice) {
      $(window).scrollTop(0);
    }

    return false;
  },

  closePopover: function (e) {
    if (
      e &&
      e.type === 'clickoutside' &&
      ($(e.target).hasClass('fc-view') ||
        $(e.target).children().hasClass('fc-event-container'))
    ) {
      return false;
    }

    events.off(events.types.popoverCreated);

    $(document).off('keydown', this.closePopoverOnEscape);

    this.$el
      .closest('.popover')
      .off('click', '.close', this.closePopover)
      .off('clickoutside', this.closePopover)
      .popover('destroy');
  },

  closePopoverWhenOtherIsCreated: function (popover) {
    if (this !== popover) {
      this.closePopover();
    }
  },

  closePopoverOnEscape: function (e) {
    if (e.keyCode === keyUtil.KEY.ESC) {
      this.closePopover();
      return false;
    }
  },

  handleRegularChange: function (e) {
    this.model.set(e.currentTarget.name, e.currentTarget.value);
  },
});

var EventDetailsView = ActivityDetailView.extend({
  templateName: 'details',

  participantsActionName: 'participants',

  createEditView: function (model) {
    var editView = new EditEventView({
      model: new Event(model.attributes, {
        portletId: this.options.portletId,
      }),
      portletId: this.options.portletId,
      $portlet: this.options.$portlet,
    });
    editView.backingModel = model;
    return editView;
  },

  i18n: i18n,

  removeActivity: function () {
    $.proxy(removeActivityInternal, this, function () {
      this.closePopover();
    })();
  },

  templateHelpers2: {
    timeSpan: function (start, end, allDay) {
      var dateFormat = allDay ? 'll' : 'lll',
        startMoment = moment(start),
        startHuman = stripYear(start, startMoment.format(dateFormat));
      if (end) {
        var endMoment = moment(end);
        if (
          startMoment.format('YYYY-MM-DD') === endMoment.format('YYYY-MM-DD')
        ) {
          if (!allDay) {
            return startHuman + ' - ' + endMoment.format('LT');
          }
        } else if (startMoment.format('YYYY') === endMoment.format('YYYY')) {
          return (
            startHuman + ' - ' + stripYear(end, endMoment.format(dateFormat))
          );
        } else {
          return startHuman + ' - ' + endMoment.format(dateFormat);
        }
      }
      return startHuman;
    },
  },
});

// CALENDAR (COLLECTION) VIEW ----------------------------------------------
var CalendarView = Backbone.View.extend({
  initialize: function (options) {
    this.options = options;

    this.listenTo(this.collection, 'add', this.addOne);
    this.listenTo(this.collection, 'reset', this.addAll);
    this.listenTo(this.collection, 'change', this.change);
    this.listenTo(this.collection, 'destroy', this.destroy);
    this.listenTo(this.collection, 'remove', this.destroy);

    events.on(
      events.types.activityUpdated,
      $.proxy(this.activityUpdated, this)
    );
    events.on(
      events.types.activityCreated,
      $.proxy(this.activityCreated, this)
    );
    events.on(
      events.types.activityDeleted,
      $.proxy(this.activityDeleted, this)
    );

    this.selectionFilterContent = getTemplate(
      this.options.$portlet,
      'filter'
    )();

    // unmark the new tasks, triggered by timed remote call (see groupPage.js)
    events.on(
      events.types.groupPageViewed,
      $.proxy(function () {
        this.collection.each(function (model) {
          model.set('markAsUnread', false);
        }, this);
      }),
      this
    );
  },

  render: function () {
    if (this.$el.is(':visible')) {
      this.renderCalendar();
    } else if ('IntersectionObserver' in window) {
      new IntersectionObserver((entries, observer) => {
        entries.forEach((entry) => {
          if (this.$el.is(':visible')) {
            this.renderCalendar();
            observer.disconnect();
          }
        });
      }).observe(this.$el[0]);
    }
  },

  renderCalendar: function() {
    this.$el.fullCalendar({
      header: {
        left: 'prev,next today',
        center: '',
        right: 'month,agendaWeek,agendaDay,agendaList svFilter',
        ignoreTimezone: false,
      },
      editable: this.options.editable,
      weekNumbers: true,
      selectable: this.options.editable,
      selectHelper: this.options.editable,
      firstDay: 1,
      droppable: this.options.editable,
      monthNames: [
        i18n('january'),
        i18n('february'),
        i18n('march'),
        i18n('april'),
        i18n('may'),
        i18n('june'),
        i18n('july'),
        i18n('august'),
        i18n('september'),
        i18n('october'),
        i18n('november'),
        i18n('december'),
      ],
      monthNamesShort: [
        i18n('jan'),
        i18n('feb'),
        i18n('mar'),
        i18n('apr'),
        i18n('may'),
        i18n('jun'),
        i18n('jul'),
        i18n('aug'),
        i18n('sep'),
        i18n('oct'),
        i18n('nov'),
        i18n('dec'),
      ],
      dayNames: [
        i18n('sunday'),
        i18n('monday'),
        i18n('tuesday'),
        i18n('wednesday'),
        i18n('thursday'),
        i18n('friday'),
        i18n('saturday'),
      ],
      dayNamesShort: [
        i18n('sun'),
        i18n('mon'),
        i18n('tue'),
        i18n('wed'),
        i18n('thu'),
        i18n('fri'),
        i18n('sat'),
      ],
      weekNumberTitle: i18n('week'),
      allDayText: i18n('allday'),
      axisFormat: i18n('axisFormat'),
      titleFormat: {
        month: i18n('titleMonth'),
        week: i18n('titleWeek'),
        day: i18n('titleDay'),
        agendaList: i18n('titleMonth'),
      },
      columnFormat: {
        month: i18n('columnMonth'),
        week: i18n('columnWeek'),
        day: i18n('columnDay'),
      },
      timeFormat: {
        '': i18n('timeFormat'),
        agenda: i18n('timeFormatAgenda'),
      },
      buttonText: {
        today: i18n('buttonTextToday'),
        month: i18n('buttonTextMonth'),
        week: i18n('buttonTextWeek'),
        day: i18n('buttonTextDay'),
        agendaList: i18n('buttonTextAgendaList'),
      },

      select: $.proxy(this.select, this),
      eventClick: $.proxy(this.eventClick, this),
      eventDrop: $.proxy(this.eventDropOrResize, this),
      eventResize: $.proxy(this.eventDropOrResize, this),
      eventRender: $.proxy(this.handleEventRender, this),
      eventAfterRender: $.proxy(this.handleEventAfterRender, this),
      eventMouseover: $.proxy(this.handleEventMouseover, this),
      drop: $.proxy(this.handleDrop, this),

      //SiteVision specific functionality created to inject filtering into fullcalendar.js
      filter: $.proxy(this.renderSelectionFilter, this),
    });

    this.$el.find('.fc-content').addClass(this.options.textClassName);

    if (this.collection.length) {
      this.addAll();
    }
  },

  activityUpdated: function (attributes) {
    var event = this.collection.get(attributes.id);
    if (event) {
      event.set(attributes);
    } else {
      this.collection.add(
        new Event(attributes, {
          portletId: this.options.portletId,
        })
      );
    }
  },

  activityCreated: function (attributes) {
    var event = this.collection.get(attributes.id);
    if (!event) {
      this.collection.add(
        new Event(attributes, {
          portletId: this.options.portletId,
        })
      );
    }
  },

  activityDeleted: function (attributes) {
    var event = this.collection.get(attributes.id);
    if (event) {
      this.collection.remove(event);
    }
  },

  handleDrop: function (date, allDay, jsEvent) {
    var task = $(jsEvent.target).data('eventObject'),
      newStart = moment(date);

    if (!task.editable) {
      return false;
    }

    if (task.start) {
      // i.e. the task is already in the calendar - keep the task duration / length of the task
      var startMoment = moment(task.start),
        duration = task.end ? moment(task.end).diff(startMoment) : null;

      if (allDay) {
        // i.e. when no time info is available - keep the old time
        startMoment.year(newStart.year());
        startMoment.month(newStart.month());
        startMoment.date(newStart.date());

        task.start = startMoment.valueOf();
        if (task.end) {
          task.end = startMoment.add(duration, 'ms').valueOf();
        }
      } else {
        task.start = toTimeStamp(date);
        if (task.end) {
          task.end = moment(task.start).add(duration, 'ms').valueOf();
        } else {
          task.end = moment(date).add(2, 'h').valueOf();
        }
      }
    } else {
      // initial drop of a task in the calendar
      task.start = toTimeStamp(date);
      task.allDay = allDay;
      if (!allDay) {
        // use a default time span
        task.end = newStart.add(2, 'h').valueOf();
      }
    }

    var calendarObject = this.collection.get(task.id);
    if (calendarObject) {
      // The task item already exists in the calendar
      calendarObject.save(task, {
        wait: true,
        success: function (model) {
          events.trigger(events.types.activityUpdated, model.attributes);
        },
      });
    } else {
      var taskEvent = new Event(task, {
        portletId: this.options.portletId,
      });
      taskEvent.save(task, {
        success: function (model) {
          events.trigger(events.types.activityUpdated, model.attributes);
        },
      });
      this.collection.add(taskEvent);
    }
  },

  select: function (startDate, endDate, allDay, jsEvent) {
    new CreateEventView({
      $portlet: this.options.$portlet,
      portletId: this.options.portletId,
      user: this.options.user,
      popoverContainer: this.options.$portlet.find('.bootstrap'),
      $target: $(jsEvent.target),
      collection: this.collection,
      model: new Event(
        {
          start: toTimeStamp(startDate),
          end:
            endDate.getTime() === startDate.getTime()
              ? ''
              : toTimeStamp(endDate),
          allDay: allDay,
        },
        {
          portletId: this.options.portletId,
        }
      ),
    }).render();
  },

  addOne: function (event) {
    this.$el.fullCalendar('renderEvent', event.toFullCalendar(), true);
  },

  addAll: function () {
    this.$el.fullCalendar('addEventSource', this.collection.toFullCalendar());
  },

  change: function (event) {
    // Look up the underlying event in the calendar and update its details from the model
    var fcEvent = this.$el.fullCalendar('clientEvents', event.get('id'))[0];
    fcEvent.title = event.get('title');
    fcEvent.location = event.get('where');
    fcEvent.description = event.get('description');
    fcEvent.start = event.get('start')
      ? event.get('start') / 1000
      : event.get('start');
    fcEvent.end = event.get('end') ? event.get('end') / 1000 : event.get('end');
    fcEvent.allDay = event.get('allDay');
    fcEvent.owner = event.get('owner');
    fcEvent.participants = event.get('participants');
    fcEvent.eventState = event.get('eventState');
    fcEvent.ownerOrParticipant = event.get('ownerOrParticipant');
    fcEvent.markAsUnread = event.get('markAsUnread');
    fcEvent.isTask = event.get('isTask');
    this.$el.fullCalendar('updateEvent', fcEvent);
  },

  destroy: function (fcEvent) {
    this.$el.fullCalendar('removeEvents', fcEvent.id);
  },

  eventDropOrResize: function (fcEvent) {
    if (!fcEvent.editable) {
      return false;
    }

    // Lookup the model that has the ID of the event and update its attributes
    var event = this.collection.get(fcEvent.id),
      allDay = fcEvent.allDay,
      startDate = toTimeStamp(fcEvent.start),
      endDate;

    if (fcEvent.end) {
      endDate = toTimeStamp(fcEvent.end);
    } else {
      // Add two hours as default since that is what calendar draws
      endDate = moment(fcEvent.start).add(2, 'h').valueOf();
    }

    event.set({
      allDay: allDay,
      start: startDate,
      end: endDate,
    });

    event.save(null, {
      wait: true,
      success: function (model) {
        events.trigger(events.types.activityUpdated, model.attributes);
      },
    });
  },

  handleExternalEventDrop: undefined,

  eventClick: function (fcEvent, jsEvent) {
    var model = this.collection.get(fcEvent.id),
      $target = $(jsEvent.target);

    if ($target.prop('popover-open')) {
      $target.removeProp('popover-open');

      return;
    }

    $target.prop('popover-open', true);

    if (fcEvent.editable && $target.hasClass('fc-title')) {
      model.fetch({
        success: $.proxy(function (model) {
          var editView = new EditEventView({
            model: new Event(model.attributes, {
              portletId: this.options.portletId,
            }),
            $portlet: this.options.$portlet,
            portletId: this.options.portletId,
          });
          editView.backingModel = model;
          editView.render();

          if (clientUtil.isTouchDevice) {
            $(window).scrollTop(0);
          }
        }, this),
      });
    } else {
      new EventDetailsView({
        model: model,
        $portlet: this.options.$portlet,
        portletId: this.options.portletId,
        popoverContainer: this.options.$portlet.find('.bootstrap'),
        $target: $(jsEvent.target),
        jsEvent: jsEvent,
        i18n: i18n,
      }).render();
    }
    return false;
  },

  handleEventAfterRender: function (fcEvent, $el) {
    // hack to be able to show the 'my item man' when the event is of min size. Must be done after the
    // rendering - fullcalendar changes the .fc-event-time when the element is small. Only when the
    // title is in a blocking element (div)
    var $titleEl = $el.find('.fc-event-title');
    if ($titleEl.length === 0) {
      // the way to identity that fullcalendar removed the title element because lack of space
      var $timeEl = $el.find('.fc-event-time'),
        $iconEl = $el.find('.sv-calendar-user-icon');
      $iconEl.prependTo($timeEl);
    }
  },

  handleEventRender: function (fcEvent, $el) {
    this.renderFilterOptions(fcEvent, $el);
    this.renderColor(fcEvent, $el);
    this.renderOwnerIcon(fcEvent, $el);
    this.renderHover(fcEvent, $el);
    this.renderTime(fcEvent, $el);
    this.renderIsNew(fcEvent, $el);

    this.filterEvent(fcEvent, $el);
  },

  renderIsNew: function (fcEvent, $el) {
    $el
      .find('.fc-agendalist-title,.fc-event-inner')
      .toggleClass('sv-new', fcEvent.markAsUnread);
  },

  renderFilterOptions: function (fcEvent, $el) {
    if (fcEvent.ownerOrParticipant) {
      $el.addClass('sv-my-event');
    }
    if (fcEvent.isTask) {
      $el.addClass('sv-event-task');
    } else {
      $el.addClass('sv-event');
    }
  },

  filterEvent: function (fcEvent, $el) {
    var onlyMine = filter.mine,
      showTasks = filter.type === 'all' || filter.type === 'tasks',
      showEvents = filter.type === 'all' || filter.type === 'events';

    if (fcEvent.isTask) {
      $el.toggleClass('env-d--none', !showTasks);
    } else {
      $el.toggleClass('env-d--none', !showEvents);
    }

    if (!fcEvent.ownerOrParticipant && onlyMine) {
      $el.addClass('env-d--none');
    }
  },

  renderColor: function (fcEvent, $el) {
    if (fcEvent.isTask) {
      $el.css({
        'background-color': this.options.eventTaskColor,
        'border-color': this.options.eventTaskColor,
      });
      if (isAgendaListItem($el)) {
        $el
          .find('.fc-agendalist-title')
          .css('border-left', '4px solid ' + this.options.eventTaskColor);
      }
    } else {
      $el.css({
        'background-color': this.options.eventColor,
        'border-color': this.options.eventColor,
      });
      if (isAgendaListItem($el)) {
        $el
          .find('.fc-agendalist-title')
          .css('border-left', '4px solid ' + this.options.eventColor);
      }
    }
  },

  renderOwnerIcon: function (fcEvent, $el) {
    if (fcEvent.ownerOrParticipant) {
      if (isAgendaListItem($el)) {
        $el
          .find('.fc-agendalist-title')
          .prepend('<i class="halflings-icon user sv-calendar-user-icon"/>');
      } else {
        $el
          .find('.fc-event-inner')
          .prepend(
            '<i class="halflings-icon white user sv-calendar-user-icon"/>'
          );
      }
    }
  },

  renderHover: function (fcEvent, $el) {
    if (fcEvent.editable) {
      $el.find('.fc-event-title').addClass('sv-event-title-owner');
    }
  },

  renderTime: function (fcEvent, $el) {
    var $time = $el.find('.fc-event-time');

    if (!fcEvent.allDay) {
      var startHuman = moment(fcEvent.start).format('LT'),
        endHuman = moment(fcEvent.end).format('LT');
      $time.html(startHuman + ' - ' + endHuman);
      $time.append('<br />');
    }
  },

  renderSelectionFilter: function ($el) {
    $el.append(this.selectionFilterContent);

    $el.on(
      'click',
      '[data-fn-filter-mine]',
      $.proxy(this.toggleOnlyMyEventsAndTasks, this)
    );
    $el.on('click', '[data-fn-filter]', $.proxy(this.setFilter, this));
  },

  setFilter: function (e) {
    var $target = $(e.currentTarget),
      $menu = $target.closest('.dropdown-menu'),
      filterValue = $target.data('fn-action'),
      $currentlySelected = $menu.find('[data-fn-action=' + filter.type + ']');

    if (filter.type === filterValue) {
      return;
    }

    filter.type = filterValue;
    this.selectFilterValue($currentlySelected, false);
    this.selectFilterValue($target, true);

    this.doFiltering();
    $menu.closest('.btn-group').removeClass('open');
    return false;
  },

  selectFilterValue: function ($el, select) {
    $el.find('i').toggleClass('sv-empty-icon', !select);
    $el.find('i').toggleClass('halflings-icon ok', select);
  },

  doFiltering: function () {
    var onlyMine = filter.mine,
      showTasks = filter.type === 'all' || filter.type === 'tasks',
      showEvents = filter.type === 'all' || filter.type === 'events';

    this.$el.find('.sv-event').each(function () {
      var $el = $(this),
        isMine = $el.hasClass('sv-my-event');
      $el.toggleClass('env-d--none', !showEvents);
      if (onlyMine) {
        if (!isMine) {
          $el.addClass('env-d--none');
        }
      }
    });
    this.$el.find('.sv-event-task').each(function () {
      var $el = $(this),
        isMine = $el.hasClass('sv-my-event');
      $el.toggleClass('env-d--none', !showTasks);
      if (onlyMine) {
        if (!isMine) {
          $el.addClass('env-d--none');
        }
      }
    });
  },

  toggleOnlyMyEventsAndTasks: function (e) {
    var $target = $(e.currentTarget),
      $menu = $target.closest('.dropdown-menu'),
      el = $target.contents().last()[0];

    filter.mine = !filter.mine;

    if (filter.mine) {
      if (el.textContent) {
        el.textContent = i18n('filterMine');
      } else {
        el.nodeValue = i18n('filterMine'); // IE8 support
      }
    } else {
      if (el.textContent) {
        el.textContent = i18n('filterAll');
      } else {
        el.nodeValue = i18n('filterAll'); // IE8 support
      }
    }

    this.doFiltering();

    $menu.closest('.btn-group').removeClass('open');
    return false;
  },
});

// INTERNAL ----------------------------------------------------------------
function isAgendaListItem($el) {
  if ($el.hasClass('fc-agendalist-day-item')) {
    return true;
  }
  return false;
}

function toTimeStamp(date) {
  return moment(date).valueOf();
}

// PORTLET INITIALIZE ------------------------------------------------------
$('.sv-calendar-portlet').each(function () {
  import(/* webpackChunkName: "fullcalendar-plugins" */ './plugins').then(
    () => {
      var $this = $(this),
        portletId = objectUtil.getObjectId($this.attr('id')),
        $cal = $this.find('.sv-fn-calendar'),
        textClassName = $cal.data('text-classname'),
        eventColor = $cal.data('event-color'),
        eventTaskColor = $cal.data('event-task-color'),
        jsNamespace = $cal.data('js-namespace'),
        editable = $cal.data('editable'),
        user = $cal.data('user');

      var eventList = new Events(sv[jsNamespace] || [], {
        portletId: portletId,
      });
      new CalendarView({
        el: $cal,
        collection: eventList,
        portletId: portletId,
        textClassName: textClassName,
        eventColor: eventColor,
        eventTaskColor: eventTaskColor,
        $portlet: $this,
        editable: editable,
        user: user,
      }).render();
    }
  );
});
