/*jshint nonew:false */
/**
 * Copyright (C) SiteVision AB 2002-2020, all rights reserved
 *
 * @author Karl Eklöf
 */
import _ from '@sv/underscore';
import $ from '@sv/jquery';
import Backbone from '@sv/backbone';
import {getPortletResourceUri, getTemplate} from '../../../util/portletUtil';
import moment from '../../../vendor/moment';
import {
   Events as events,
   ObjectUtil as objectUtil,
   Ajax as ajax,
   KeyUtil as keyUtil,
   i18n as _i18n,
} from '@sv/util';

const $window = $(window),
   i18cache = {};

let lastId = null;

const i18n = function(selector, args) {
   let translated;

   if (!args && i18cache[selector]) {
      return i18cache[selector];
   }

   translated = _i18n.getText('portlet.social.messages.messages', selector, args);

   if (!args) {
      i18cache[selector] = translated;
   }
   return translated;
};

// Model
const Message = Backbone.Model.extend({
   defaults: {
      author: '',
      identity: '',
      message: '',
      type: '',
      date: new Date(),
      buddyIconUrl: ''
   }
});

const Messages = Backbone.Collection.extend({

   model: Message,

   initialize: function(options) {
      this.portletId = options.portletId;
      this.id = options.id;
   },

   comparator: function(model) {
      return moment(model.get('date')).unix() * -1;
   },

   url: function() {
      return getPortletResourceUri(this.portletId, 'message') + '&conversationId=' + this.id;
   },

   parse: function(data) {
      return data.messages;
   }

});

// VIEWS ------------------------------------------------
const MessageView = Backbone.View.extend({

   tagName: 'li',

   initialize: function(options) {
      this.identity = options.identity;
      this.messageTemplate = options.messageTemplate;
      this.selfTemplate = options.selfTemplate;
      this.selfMessage = this.identity === this.model.get('identity');
   },

   className: 'sv-message',

   render: function() {
      if (this.selfMessage) {
         this.$el.addClass('sv-right').html(this.selfTemplate(this.model.toJSON()));
      } else {
         this.$el.addClass('sv-left').html(this.messageTemplate(this.model.toJSON()));
      }
      return this;
   }
});

const MessagesView = Backbone.View.extend({

   initialize: function(options) {
      this.identity = options.identity;
      this.portletId = options.portletId;
      this.$list = this.$el.find('ul.sv-message-list');
      this.$chat = this.$el.find('div.sv-chat');
      this.messageTemplate = options.messageTemplate,
      this.selfTemplate = options.selfTemplate,
      this.listenTo(this.collection, 'reset', this.handleDataLoaded);
      this.listenTo(this.collection, 'add', this.addOne);
   },

   events: {
      'click .sv-message-show-more': 'loadMoreMessages'
   },

   handleDataLoaded: function() {
      this.clearList();
      this.render();
   },

   render: function() {
      this.collection.each(function(item) {
         this.prependOne(item);
      }, this);
   },

   addOne: function(model, collection, options) {
      if (options.append) {
         this.appendOne(model);
      } else {
         this.prependOne(model);
      }
   },

   prependOne: function(message) {
      var view = new MessageView({
         model: message,
         messageTemplate: this.messageTemplate,
         selfTemplate: this.selfTemplate,
         identity: this.identity
      });

      this.$list.prepend(view.render().el);
      events.trigger(events.types.updateRelativeDates, view.$el);
   },

   appendOne: function(message) {
      var view = new MessageView({
         model: message,
         messageTemplate: this.messageTemplate,
         selfTemplate: this.selfTemplate,
         identity: this.identity
      });

      this.$list.append(view.render().el);

      this.scrollToBottom();

      events.trigger(events.types.updateRelativeDates, view.$el);
   },

   prependAll: function(messages) {
      messages.each(this.prependOne, this);
   },

   loadMoreMessages: function() {
      var lastPosition;

      if (lastId === false) {
         return;
      }

      if (lastId === null) {
         lastId = this.collection.last().get('id');
      }

      lastPosition = this.$chat.height();

      ajax.doGet({
         url: getPortletResourceUri(this.portletId, 'message') + '&conversationId=' + this.model.id + '&last=' + lastId,
         context: this,
         success: function(data) {
            var showMore = this.$el.closest('.sv-messages-portlet').find('.sv-show-more-container'),
               messages = data.messages,
               hasMore = data.hasMore;

            this.collection.add(messages);

            if (this.collection.last()) {
               lastId = this.collection.last().get('id');
            } else {
               lastId = false;
            }

            if (hasMore) {
               showMore.removeClass('env-d--none');
            } else {
               showMore.addClass('env-d--none');
            }

            this.scrollTo(lastPosition);
         }
      });
   },

   clearList: function() {
      this.$list.empty();
   },

   scrollToBottom: function() {
      this.scrollTo(0);
   },

   scrollTo: function(y) {
      this.$chat.offsetParent().scrollTop(this.$chat.height() - y);
   }
});

var $html = $('html'),
   $body = $('body'),
   chatAllPortlets = $('.sv-messages-portlet'),
   supportsMp3,
   newMessageSound = document.createElement('audio'),
   htmlPadding,
   scrollbarAdjust,
   scrollbarSize;

supportsMp3 = (!!(newMessageSound.canPlayType && newMessageSound.canPlayType('audio/mpeg;').replace(/no/, '')));
newMessageSound.src = supportsMp3 ? '/sitevision/portlet/chat/dingling.mp3' : '';

htmlPadding = parseInt($html.css('padding-right'), 10);

scrollbarSize = (function () { // thx walsh
   var scrollDiv = $('<div style="width:100px;height:100px;overflow:scroll;position:absolute;top:-9999em;" />')[0];
   document.body.appendChild(scrollDiv);
   var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;
   document.body.removeChild(scrollDiv);
   return (scrollbarWidth + htmlPadding);
}());

scrollbarAdjust = function() {
   if (!scrollbarSize || $body.height() < $window.height()) {
      return 0;
   }
   return scrollbarSize;
};

const getStoredValue = function(identity) {
   if ('localStorage' in window && window.localStorage !== null) {
      return window.localStorage.getItem('sv-conversation-' + identity);
   }
};

const setStoredValue = function(identity, val) {
   if ('localStorage' in window && window.localStorage !== null && val !== null) {
      window.localStorage.setItem('sv-conversation-' + identity, val);
   }
};

const removeStoredValue = function(aIdentity) {
   window.localStorage.removeItem('sv-conversation-' + aIdentity);
};

chatAllPortlets.each(function() {

   var $this = $(this),
      $body = $('body'),
      portletId = objectUtil.getObjectId($this.attr('id')),
      content = $this.find('div.sv-chat'),
      chatContainer = $this.find('.sv-chat-container'),
      contentPanel = $this.find('.sv-panel-body'),
      identity = content.data('identity'),

      inEditMode = $body.hasClass('sv-editing-mode'),

      // ui
      input = $this.find('.sv-chat-input'),
      closeButton = $this.find('a.sv-close'),
      participantPanel = $this.find('.sv-participants-panel'),
      participantSelector = $this.find('[data-participants]'),
      typingInfo = $this.find('.sv-typing'),
      panelTitle = $this.find('.sv-panel-title'),
      conversationMenu = $this.find('.sv-conversation-menu'),
      messageBadge = $this.find('.sv-message-badge'),
      showMore = $this.find('.sv-show-more-container'),
      showChat = $this.find('.sv-chat-input'),
      messageList = $this.find('ul.sv-message-list'),

      // templates
      messageTemplate = getTemplate($this, 'message'),
      selfTemplate = getTemplate($this, 'selfmessage'),
      typingTemplate = getTemplate($this, 'typingmessage'),
      participantTemplate = getTemplate($this, 'chatparticipant'),
      contactTemplate = getTemplate($this, 'contact'),
      typing = false,
      typingMarkerTimeout,

      historyLoaded = false,
      messageBuffer = [],

      // Message collection
      messages,
      messagesView,

      contacts,
      identityId,

      conversationId,
      messageId,
      storageKey;

   // Scroll and draggable
   chatContainer.draggable({
      axis: 'x',
      containment: 'body',
      handle: '.sv-chat-titlebar'
   });

   chatContainer.on('mousedown mousemove', '.popover', function (e) {
      // Stop window from moving when pointer is in popover
      e.stopPropagation();
   });

   var storage = (function() {
      try {
         if ('localStorage' in window && window.localStorage !== null) {
            return window.localStorage;
         }
      } catch (e) {
         console.log('error + e');
      }
   }());

   var postMessage = function(message) {
      return ajax.doPut({
         url: getPortletResourceUri(portletId, 'message'),
         data: message
      }).done(function(data) {
         if (!conversationId) {
            startChat(data.conversation);
         }
      });
   };

   var updateLastVisit = _.throttle(function() {
      if (conversationId) {
         var newMessageId = messages.first().get('id');
         if (messageId !== newMessageId) {
            messageId = newMessageId;
            return ajax.doPut({
               url: getPortletResourceUri(portletId, 'conversation'),
               data: { conversation: conversationId, message: messageId }
            }).done(function(data) {
               if (data.hasNewMessages) {
                  messageBadge.show();
               } else {
                  messageBadge.hide();
               }
            });
         }
      }
   }, 1000);

   var closeChat = function() {
      if (conversationId) {
         events.off('server-' + conversationId, onMessage);
      }

      if (messages) {
         messages.reset();
      }

      input.off('keydown');
      input.off('keyup');

      panelTitle.text('');
      input.val('');
      setStoredValue(identity, null);

      showMore.addClass('env-d--none');
      participantSelector.val('').trigger('change');
      participantPanel.show();
      identityId = undefined;
      conversationId = undefined;
      lastId = null;
      historyLoaded = false;

      input.off('focusin');
      contentPanel.off('click');

      removeStoredValue(identity);
   };

   var onMessage = function(message) {
      if (!historyLoaded) {
         messageBuffer.push(message);
         return;
      }

      if (message.type === 'typing') {
         if (message.identity !== identity) {
            // update typing info
            message.message =  message.author + ' ' + i18n('typing');
            typingInfo.html(typingTemplate(message));
            typingInfo.show();

            messagesView.scrollToBottom();

            if (typingMarkerTimeout) {
               clearTimeout(typingMarkerTimeout);
            }

            typingMarkerTimeout = setTimeout(function() {
               typingInfo.hide();
            }, 10000);
         } else {
            updateLastVisit();
         }
      } else if (message.type === 'message') {
         if (typingMarkerTimeout) {
            clearTimeout(typingMarkerTimeout);
         }
         typingInfo.hide();

         messages.add(message, { append: true});
      }
   };

   var loadContacts = function() {
      participantSelector.select2('destroy');

      var deferred = $.Deferred(),
         selectOptions = {
            multiple: true,
            width: '100%',
            openOnEnter: false,
            placeholder: i18n('participants'),

            formatResult: function(result, label, query) {

               var userName = _.unescape(result.text),
                  matchPosition,
                  textBefore = '',
                  matchedText = '',
                  textAfter;

               matchPosition = query.term.length > 0 ? userName.toUpperCase().indexOf(query.term.toUpperCase()) : -1;

               if (matchPosition >= 0) {
                  textBefore = userName.substring(0, matchPosition);
                  matchedText = userName.substring(matchPosition, matchPosition + query.term.length);
                  textAfter = userName.substr(matchPosition + query.term.length);
               } else {
                  textAfter = userName;
               }

               return participantTemplate({
                  iconURL: result.iconURL,
                  textBefore: textBefore,
                  matchedText: matchedText,
                  textAfter: textAfter
               });

            },
            formatSelection: function(data) {
               return contactTemplate({ text: _.unescape(data.text) });
            },
            escapeMarkup: function(m) { return m; }

         };


      if (contacts) {
         participantSelector.select2($.extend(selectOptions, {
            data: contacts
         }));

         deferred.resolve(contacts);
         return deferred.promise();
      } else {
         ajax.doGet({
            url: getPortletResourceUri(portletId, 'contacts')
         }).done(function(contactList) {
            contacts = contactList;

            participantSelector.select2($.extend(selectOptions, {
               data: contacts
            }));

            deferred.resolve(contacts);
            return deferred.promise();
         });
      }
   };

   var loadConversation = function(conversationId, updateLastVisitCallback) {
      messages.id = conversationId;

      messages.fetch({
         reset: true,
         success: function(collection, data) {

            updateChatTitle(data.participants);
            setStoredValue(identity, conversationId);

            if (messageList.find('li').length > 0) {
               messagesView.scrollToBottom();
            }

            contentPanel.on('click', updateLastVisit);
            input.on('focusin', updateLastVisit);

            messagesView.trigger('conversationLoaded');

            if (data.hasMore) {
               showMore.removeClass('env-d--none');
            } else {
               showMore.addClass('env-d--none');
            }

            if (data.editable) {
               showChat.removeClass('env-d--none');
            } else {
               showChat.addClass('env-d--none');
            }

            if (updateLastVisitCallback) {
               updateLastVisitCallback();
            }
         }
      });
   };

   var updateChatTitle = function(participants) {
      var title = _.map(participants, function(item) {
            if (item.available) {
               if (item.online) {
                  return '<span style="color: #5ab42d">&#x25CF;</span> ' + _.escape(item.shortName);
               } else {
                  return '<span style="color: red">&#x25CF;</span> ' + _.escape(item.shortName);
               }
            }
         }).join('&nbsp;');

      panelTitle.html(title);
   };

   var handleEscape = function(e) {
      if (e.keyCode === 27) {
         closeChat();
         hideMessagePanel();
         return false;
      }
   };

   var handleKeyup = function() {
      if (storage && storageKey) {
         storage.setItem(storageKey, input.val());
      }
   };

   var startChat = function(aConversationId, updateLastVisitCallback) {
      conversationId = aConversationId;
      storageKey = '';
      showMessagePanel();

      if (conversationId) { //TODO: Verify server events.
         events.onServerEvent('server-' + conversationId, onMessage).done(function() {
            // load history
            loadConversation(conversationId, updateLastVisitCallback);
         });

         participantPanel.hide();

         messagesView.on('conversationLoaded', function() {
            // add messages
            historyLoaded = true;
            _.each(messageBuffer, function(message) {
               onMessage(message);
            });
            messageBuffer = [];

            setTimeout(function(){
               messagesView.scrollToBottom();
               messagesView.off('conversationLoaded');
            }, 1);
         });

         storageKey = 'msgclval-' + conversationId;

         input.trigger('focus');
         if (storage && storage.getItem(storageKey)) {
            input[0].value = '';
            input[0].value = storage.getItem(storageKey);
         }

      } else {
         $this.find('.select2-input').trigger('focus');
      }
   };

   var moveMessagePanel = function(left) {
      if (_.isNumber(left)) {
         chatContainer.offset({ left: left });
      } else {
         chatContainer.css({ left: 'auto' });
      }
   };

   var bodyStyle = $body.attr('style') || '';

   var hideMessagePanel = function() {
      chatContainer.hide();

      if (bodyStyle) {
         $body.attr('style', bodyStyle);
      }
   };

   var showMessagePanel = function() {
      bodyStyle = $body.attr('style') || '';
      if (window.matchMedia && !mediaLargeScreen.matches) {
         $body.css('overflow', 'hidden');
      }
      chatContainer.show();
      input.on('keydown', handleEscape);
      input.on('keyup', handleKeyup);

      moveMessagePanel(chatContainer.offset().left);
   };

   var mediaLargeScreen = (window.matchMedia) ? window.matchMedia('(min-width: 769px)') : {};

   var handleWindowResize = _.debounce(function() {
      if (chatContainer && chatContainer.length > 0) {
         var chatWidth = chatContainer.width(),
            chatRightPosition = chatContainer.offset().left + chatWidth,
            windowWidth = $(window).width(),
            newPosition;
         if (chatRightPosition > windowWidth) {
            newPosition = windowWidth - chatWidth - 20;
            newPosition = (newPosition > 0) ? newPosition : 0;
            moveMessagePanel(newPosition);
         }
      }
   }, 500);

   var handleMouseEnter = function() {
      var scrollbarWidth = scrollbarAdjust();
      if (scrollbarWidth > htmlPadding) {
         $html.css({
            paddingRight: scrollbarWidth
         });
      }
      $body.css({
         height: '100%',
         overflow: 'hidden'
      });
   };

   var handleMouseLeave = function() {
      if (scrollbarAdjust() > htmlPadding) {
         $html.css({
            paddingRight: htmlPadding
         });
      }
      $body.css({
         height: 'auto',
         overflow: 'inherit'
      });
   };

   var addLargeScreenListeners = function() {
      $(window).on('resize', handleWindowResize);
      contentPanel
         .on('mouseenter', handleMouseEnter)
         .on('mouseleave', handleMouseLeave);
   };

   var removeLargeScreenListeners = function() {
      $(window).off('resize', handleWindowResize);
      contentPanel
         .off('mouseenter', handleMouseEnter)
         .off('mouseleave', handleMouseLeave);
   };

   if (mediaLargeScreen.addListener) {

      mediaLargeScreen.addListener(function() {
         if (mediaLargeScreen.matches) {
            addLargeScreenListeners();
         } else {
            removeLargeScreenListeners();
         }
      });

   }

   if (!window.matchMedia || mediaLargeScreen.matches) {
      addLargeScreenListeners();
   }

   // bind events
   events.on('showMessages', function(ping, aConversationId) {

      if (inEditMode) {
         return;
      }

      if (ping) {
         messageBadge.show();

         if (supportsMp3 && !input.is(':focus')) {
            newMessageSound.play();
         }
      }

      if (!conversationId && !chatContainer.is(':visible') || (!ping && (aConversationId !== conversationId))) {
         // clean up
         closeChat();

         // show chat window
         var updateLastVisitCallback = ping ? undefined : updateLastVisit;
         startChat(aConversationId, updateLastVisitCallback);
      } else {
         input.trigger('focus');
      }

   });

   events.on('sendMessage', function(aIdentityId) {
      if (inEditMode) {
         return;
      }

      // clean up
      closeChat();

      identityId = aIdentityId;

      ajax.doGet({
         url: getPortletResourceUri(portletId, 'member') + '&identityId=' + identityId
      }).done(function(memberInfo) {
         participantPanel.hide();
         showMessagePanel();
         updateChatTitle([ memberInfo ]);
         if (!window.matchMedia || window.matchMedia('(min-width: 769px)').matches) {
            input.trigger('focus');
         }

         if (storage && storageKey) {
            storage.removeItem(storageKey);
         }

      });
   });

   conversationMenu.on('click', function(e) {
      events.trigger('showConversations', e);
   }).bsPopoverAriaButton();

   closeButton.on('click', function() {
      closeChat();
      hideMessagePanel();
      return false;
   });

   input.on('keypress', function(e) {
      var msg = $(this).val(),
         keyCode = keyUtil.getKeyCodeFromEvent(e);

      if (keyCode === keyUtil.KEY.RETURN && msg.length > 0 && (conversationId || !!participantSelector.val() || identityId)) {
         $(this).val('');

         // post message
         postMessage({
            type: 'message',
            message: msg,
            conversation: conversationId,
            participants: participantSelector.val() || identityId
         });

         typing = false;
      } else if (!typing && !keyUtil.isNonTextModifiableKeyCode(keyCode) && conversationId) {
         typing = true;

         // post typing message
         postMessage({
            type: 'typing',
            message: 'typing',
            conversation: conversationId
         });

         if (typingMarkerTimeout) {
            clearTimeout(typingMarkerTimeout);
         }

         typingMarkerTimeout = setTimeout(function() {
            typing = false;
         }, 5000);
      }
   });

   loadContacts();

   messages = new Messages({
      id: '',
      portletId: portletId
   });

   messagesView = new MessagesView({
      el: $this,
      portletId: portletId,
      model: messages,
      collection: messages,
      identity: identity,
      messageTemplate: messageTemplate,
      selfTemplate: selfTemplate,
      typingTemplate: typingTemplate
   });

   //check if auto start
   var aConversationId = getStoredValue(identity);
   if (aConversationId) {
      if (inEditMode) {
         return;
      }

      closeChat();
      startChat(aConversationId);
   }

   return false;
});
